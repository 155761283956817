<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
      <v-card class="v-card-bottom-30">
        <v-card-title style="height: 60px;">
          <h4>สต๊อก</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">คลังสินค้า Packhai</span> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">รายละเอียด</span>
        </v-card-title>
      </v-card>
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          <v-row>
            <v-col cols="12" class="py-2">
                <img v-if="PhotoLink!=null && PhotoLink!='' && PhotoLink!='null'" :src="PhotoLink" width="100"/>
              <span class="pa-5" v-if="ProductCode=='C2023'">ตุ๊กตา {{' '+StockName }}</span>
              <span class="pa-5" v-else>{{ StockName }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row class="py-1">
            <v-col cols="12" md="1" class="py-1">
              <span><b> เลือกวันที่ : </b></span>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="py-1">
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="startDate"
                    label="เริ่มต้น"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates"/>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="py-1">
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="endDate"
                    label="สิ้นสุด"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedDates"/>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3" class="py-1">
              <v-btn :elevation="1" class="fn-14" color="primary" @click="StartPage()"><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn>
            </v-col>
            <v-col cols="6" md="4" class="py-1" align="right">
              <v-btn :elevation="1" class="fn-14" color="success" outlined @click="export_to_excell()">export to excel</v-btn>
            </v-col>
          </v-row>

          <v-row class="hidden-sm-and-down">
            <v-col cols="12">
              <v-data-table
                :headers="headerHistory"
                :items="dataHistory"
                hide-default-footer
                :items-per-page="itemsPerPageHistory"
                :key="historyKey"
                class="packhai-border-table packhai-table"
              >
                <template v-slot:item.Description="{ item }">
                  <div class="text-left">
                    <span v-if="item.RemoveQuantity>0" class="pl-10">{{item.Description}}</span>
                    <span v-else>{{item.Description}}</span>
                  </div>
                </template>
                <template v-slot:item.AddQuantity="{ item }">
                  <div class="text-right">
                    <span v-if="item.AddQuantity>0" class="color-green"><b>{{formatMoney(item.AddQuantity)}}</b></span>
                  </div>
                </template>
                <template v-slot:item.RemoveQuantity="{ item }">
                  <div class="text-right">
                    <span v-if="item.RemoveQuantity>0" class="color-red"><b>{{formatMoney(item.RemoveQuantity)}}</b></span>
                  </div>
                </template>
                <template v-slot:item.TotalQuantity="{ item }">
                  <div class="text-right">
                    <span><b>{{formatMoney(item.TotalQuantity)}}</b></span>
                  </div>
                </template>
                <!-- <template v-slot:item.Edit="{ item }">
                  <v-btn icon @click="editHistoryDialogOpen(item.ID)"><v-icon color="info">mdi-pencil</v-icon></v-btn>
                </template>
                <template v-slot:item.Delete="{ item }">
                  <v-btn icon @click="deleteStockShopHistory(item.ID)"><v-icon color="red">mdi-delete</v-icon></v-btn>
                </template> -->
                <template v-slot:item.Detail="{ item }">
                  {{item.Description}}
                </template>
              </v-data-table>
            </v-col>
          </v-row>


          <v-row class=" hidden-md-and-up">
            <v-col v-for="(item, i) in dataHistory" :key="i" cols="12">
              <div class="card-table d-flex flex-no-wrap">
                <div class="lineheight">
                  <span v-if="item.AddQuantity!='' && item.AddQuantity!=null"><b style="color: green;">สินค้าเข้า: </b>{{item.AddQuantity}}<br/></span>
                  <span v-else><b style="color: red;">สินค้าออก: </b>{{item.RemoveQuantity}}<br/></span>
                  <b>วันที่: </b>{{item.CreatedDatetime}}<br/>
                  <b>ID: </b>{{item.ID}}<br/>
                  <b>รายละเอียด: </b>{{item.Description}}<br/>
                </div>
                <v-spacer/>
                <div>
                  คงเหลือ: {{item.TotalQuantity}}<br/>
                  <!-- <v-btn icon @click="editHistoryDialogOpen(item.ID)"><v-icon color="info" style="font-size: 22px;">mdi-pencil</v-icon></v-btn>
                  <v-btn icon @click="deleteStockShopHistory(item.ID)"><v-icon color="red">mdi-delete</v-icon></v-btn> -->
                </div>
              </div>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>


    </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/website/components/Loading'
import { stockService } from '@/website/global'
import { format_order_id, isNumberWithDot, isNumberWNoDot, formatMoney, DateNowNotNull} from '@/website/global_function'
export default {
    components: {
        Loading
    },

    data: () => ({
        page_loading: true,
        loading: false,

        PhotoLink: 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Branch/branch1202102131330480',
        // StockName: null,
        StockName: 'รองเท้าหัวโต',
        // PhotoLink: null,
        ProductCode: null,

        // วันที่
        dateNow: [],
        startDate: [],
        menuStartDate: false,
        checkStart: true,
        endDate: [],
        menuEndDate: false,
        checkEnd: true,

        stockShopId: 0,
        historyKey: 1,
        headerHistory: [
          // { align: 'center', text: 'ID', value: 'ID', sortable: false, width: '100' },
          { align: 'center', text: 'วันที่', value: 'CreatedDatetime', sortable: false, width: '180' },
          { align: 'center', text: 'รายละเอียด', value: 'Description', sortable: false },
          { align: 'center', text: 'สินค้าเข้า(ชิ้น)', value: 'AddQuantity', sortable: false, width: '180' },
          { align: 'center', text: 'สินค้าออก(ชิ้น)', value: 'RemoveQuantity', sortable: false, width: '180' },
          { align: 'center', text: 'สินค้าคงเหลือ(ชิ้น)', value: 'TotalQuantity', sortable: false, width: '180' },
          // { align: 'center', text: 'แก้ไข', value: 'Edit', sortable: false, width: '70' },
          // { align: 'center', text: 'ลบ', value: 'Delete', sortable: false, width: '70' }
        ],
        dataHistory: [],
    }),

    async created() {



        this.stockShopId = localStorage.getItem('stockShopId')
        this.StockName = localStorage.getItem('StockName')
        this.PhotoLink = localStorage.getItem('PhotoLink')
        this.ProductCode = localStorage.getItem('ProductCode')
        this.dateNow = JSON.parse(localStorage.getItem('dateNow'))
        this.startDate = this.dateNow[0]
        this.endDate = this.dateNow[1]

        if(this.stockShopId == null || this.stockShopId == ''){
          this.$router.push('/stock')
        }

        await this.StartPage(true)
        this.page_loading = false
    },

    computed: {
      itemsPerPageHistory: function() {
        return this.dataHistory.length
      },
      totalQuantity: function() {
        try{
          var newTotal = this.dataHistory[this.dataHistory.length-1]['TotalQuantity']
          newTotal += this.addQuantity==''?(this.removeQuantity==''?0:(-1)*parseInt(this.removeQuantity)):parseInt(this.addQuantity)
          return newTotal
        }catch(e){
          return null
        }

      }
    },

    watch: {
      startDate: async function(newVal, oldVal) {
        if (new Date(newVal)>=new Date(this.endDate)){
          this.checkStart = false
          this.startDate = oldVal
        }else if(this.checkStart){
          try { this.$refs.menuStartDate.save(this.startDate) }catch(err) { console.log(this.startDate); }
        }else{
          this.checkStart = true
        }
      },
      endDate: async function(newVal, oldVal) {
        if (new Date(newVal)<=new Date(this.startDate) || new Date(newVal)>new Date()){
          this.checkEnd = false
          this.endDate = oldVal
        }else if(this.checkEnd){
          try { this.$refs.menuEndDate.save(this.endDate) }catch(err) { console.log(this.endDate); }
        }else{
          this.checkEnd = true
        }
      },
    },

    methods: {
      format_order_id,
      isNumberWithDot,
      isNumberWNoDot,
      formatMoney,
      DateNowNotNull,
      async StartPage(Bl){
        this.loading = true
        this.dateNow = [this.startDate,this.endDate]
        var response = await axios.post(stockService+'stockFC/loadDataHistory', {
          'stock_shop_id': this.stockShopId,
          'selectDate': this.dateNow,
          'Search': Bl
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status=="success"){
          this.dataHistory = response.data.data
          if (response.data.resultDate.length>0){
            this.dateNow = response.data.resultDate
            this.startDate = this.dateNow[0]
            this.endDate = this.dateNow[1]
          }
        }
        this.loading = false
      },
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      async export_to_excell () {
        if (this.dataHistory.length == 0){
          this.$swal({
            type: 'warning',
            title: 'ไมพบข้อมูลที่ต้องการ EXPORT !!',
          })
          return
        }
        this.loading = true
        let response = await axios.post(stockService + 'stock_history/export_to_excel', {
          'data_export': this.dataHistory,
          'stock_name' : this.StockName
        })
        if (response.data.status == 'success') {
            var filename = response.data.this_path
            window.open(stockService + 'stock_history/download/'+filename, "_blank");
            this.loading = false
        }

      },

    }
}
</script>

<style scoped>
/*
Image
*/
/* fix image center */
/* >>>.v-responsive {
    margin-left: auto;
    margin-right: auto;
} */
</style>
