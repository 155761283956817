<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
        <v-card class="v-card-bottom-30">
            <v-row class="ma-0 pa-4">
                <v-col cols="12" md="3" lg="2" class="pa-0">
                    <span class="ma-2">เลือกช่วงวันที่ที่จะค้นหา :</span>
                </v-col>
                <v-col cols="12" md="4" lg="3" class="pa-0">
                    <v-menu
                        ref="menu_date"
                        v-model="menu_date"
                        :close-on-content-click="false"
                        :return-value.sync="dates"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedDateFormatted"
                                @change="value => computedDateFormatted = value"
                                autocomplete="off"
                                label="เลือกวันที่"
                                outlined
                                prepend-icon=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-on="on"
                                hide-details
                                class="ma-2"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" range no-title class="my-0">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dates = []">Reset</v-btn>
                            <v-btn text color="primary" @click="menu_date = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu_date.save(dates)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="4" class="pa-0">
                    <v-btn color="primary" class="ma-2 mt-3" @click="bt_search_click()"><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-row class="mt-5 mx-0">
            <v-col cols="12" class="pa-0">
                <v-card class="pa-4">
                    <v-img :src="stock_item.PhotoLink" max-width="50" max-height="50" class="d-inline-block mr-3"></v-img>
                    <h1 class="d-inline-block">{{ stock_item.Name }}</h1>
                    <div class="separator mb-10"></div>

                    <v-data-table
                        :headers="table_headers"
                        :items="table_list"
                        :items-per-page="1000000"
                        :loading="table_loading"
                        hide-default-footer
                        class="packhai-border-table"
                    >
                        <template v-slot:item.Description="{ item }">
                            <div v-if="item.AddQuantity != null">
                                {{ item.Description }}
                            </div>
                            <div v-else>
                                <div class="row">
                                    <div class="col-2"></div>{{ item.Description }}
                                </div>
                            </div>
                        </template>
                        <template v-slot:item.AddQuantity="{ item }">
                            <font color="success">{{ item.AddQuantity }}</font>
                        </template>
                        <template v-slot:item.RemoveQuantity="{ item }">
                            <font color="error">{{ item.RemoveQuantity }}</font>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import { stockService } from '@/website/global'
export default {
    components: {
        Loading
    },

    data: () => ({
        page_loading: true,
        dates: [],
        menu_date: false,

        table_headers: [
            { text: 'เวลา', value: 'CreatedDatetime', align: 'left', sortable: false },
            { text: 'รายละเอียด', value: 'Description', align: 'left', sortable: false },
            { text: 'จำนวนเข้า', value: 'AddQuantity', align: 'right', sortable: false },
            { text: 'จำนวนออก', value: 'RemoveQuantity', align: 'right', sortable: false },
            { text: 'จำนวนทั้งหมด', value: 'TotalQuantity', align: 'right', sortable: false },
        ],
        table_list: [],
        table_loading: false,
    }),

    async created() {
        this.stock_item = localStorage.getItem('stock_item')
        this.stock_item = JSON.parse(this.stock_item)
        document.title = this.stock_item.Name
        let response = await axios.post(stockService + 'stock_history/get_last_update_stock', {
            'stock_id': this.stock_item.ID
        })
        if (response.data.status == 'success') {
            const last_update_stock = new Date(response.data.last_update_stock)
            this.dates.push(last_update_stock.toISOString().substr(0, 10))
            last_update_stock.setUTCDate(last_update_stock.getUTCDate() - 1)
            this.dates.push(last_update_stock.toISOString().substr(0, 10))
        } else {
            console.log(response.data.msg)
        }

        await this.load_data(this.stock_item.ID)
        this.page_loading = false
    },

    computed: {
        computedDateFormatted () {
            return this.formatDate(this.dates)
        },
    },

    methods: {
        formatDate (date) {
            if (date.length == 0) return null

            if (date.length == 1) {
                const [year, month, day] = date[0].split('-')
                return `${day}-${month}-${year}`
            } else {
                const [year_from, month_from, day_from] = date[0].split('-')
                const [year_to, month_to, day_to] = date[1].split('-')
                if (date[0] < date[1]) {
                    return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
                } else {
                    return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
                }
            }
        },

        async load_data(stock_id) {
            var date_from = null
            var date_to = null
            if (this.dates.length == 1) {
                date_from = this.dates[0] + ' 00:00:00'
                date_to = this.dates[0] + ' 23:59:59'
            } else if (this.dates.length == 2) {
                if (this.dates[0] < this.dates[1]) {
                    date_from = this.dates[0] + ' 00:00:00'
                    date_to = this.dates[1] + ' 23:59:59'
                } else {
                    date_from = this.dates[1] + ' 00:00:00'
                    date_to = this.dates[0] + ' 23:59:59'
                }
            }
            let response = await axios.post(stockService + 'stock_history/load_data', {
                'stock_id': stock_id,
                'date_from': date_from,
                'date_to': date_to
            })
            if (response.data.status == 'success'){
                this.table_list = response.data.stock_history
            } else {
                console.log(response.data.msg)
            }
        },

        async bt_search_click() {
            this.table_list = []
            this.table_loading = true
            await this.load_data(this.stock_item.ID)
            this.table_loading = false
        }
    }
}
</script>

<style scoped>
/*
Image
*/
/* fix image center */
/* >>>.v-responsive {
    margin-left: auto;
    margin-right: auto;
} */
</style>
