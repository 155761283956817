import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page_loading)?_c('div',[_c('loading',{attrs:{"page_loading":_vm.page_loading}})],1):_c('div',[_c(VCard,{staticClass:"v-card-bottom-30"},[_c(VRow,{staticClass:"ma-0 pa-4"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('span',{staticClass:"ma-2"},[_vm._v("เลือกช่วงวันที่ที่จะค้นหา :")])]),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"4","lg":"3"}},[_c(VMenu,{ref:"menu_date",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({staticClass:"ma-2",attrs:{"value":_vm.computedDateFormatted,"autocomplete":"off","label":"เลือกวันที่","outlined":"","prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""},on:{"change":function (value) { return _vm.computedDateFormatted = value; }}},on))]}}]),model:{value:(_vm.menu_date),callback:function ($$v) {_vm.menu_date=$$v},expression:"menu_date"}},[_c(VDatePicker,{staticClass:"my-0",attrs:{"range":"","no-title":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dates = []}}},[_vm._v("Reset")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu_date = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu_date.save(_vm.dates)}}},[_vm._v("OK")])],1)],1)],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"4"}},[_c(VBtn,{staticClass:"ma-2 mt-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.bt_search_click()}}},[_c(VIcon,[_vm._v("mdi-magnify")]),_vm._v("ค้นหา")],1)],1)],1)],1),_c(VRow,{staticClass:"mt-5 mx-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-4"},[_c(VImg,{staticClass:"d-inline-block mr-3",attrs:{"src":_vm.stock_item.PhotoLink,"max-width":"50","max-height":"50"}}),_c('h1',{staticClass:"d-inline-block"},[_vm._v(_vm._s(_vm.stock_item.Name))]),_c('div',{staticClass:"separator mb-10"}),_c(VDataTable,{staticClass:"packhai-border-table",attrs:{"headers":_vm.table_headers,"items":_vm.table_list,"items-per-page":1000000,"loading":_vm.table_loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.Description",fn:function(ref){
var item = ref.item;
return [(item.AddQuantity != null)?_c('div',[_vm._v(" "+_vm._s(item.Description)+" ")]):_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"}),_vm._v(_vm._s(item.Description)+" ")])])]}},{key:"item.AddQuantity",fn:function(ref){
var item = ref.item;
return [_c('font',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.AddQuantity))])]}},{key:"item.RemoveQuantity",fn:function(ref){
var item = ref.item;
return [_c('font',{attrs:{"color":"error"}},[_vm._v(_vm._s(item.RemoveQuantity))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }