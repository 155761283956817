import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page_loading)?_c('div',[_c('loading',{attrs:{"page_loading":_vm.page_loading}})],1):_c('div',[_c('loading',{attrs:{"loading":_vm.loading}}),_c(VCard,{staticClass:"v-card-bottom-30"},[_c(VCardTitle,{staticStyle:{"height":"60px"}},[_c('h4',[_vm._v("สต๊อก")]),_vm._v(" "),_c(VIcon,[_vm._v("mdi-chevron-right")]),_c('span',{staticClass:"font-weight-regular body-1"},[_vm._v("คลังสินค้า Packhai")]),_vm._v(" "),_c(VIcon,[_vm._v("mdi-chevron-right")]),_c('span',{staticClass:"font-weight-regular body-1"},[_vm._v("รายละเอียด")])],1)],1),_c(VCard,{staticClass:"v-card-margin-bottom-30",attrs:{"elevation":"1"}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{staticClass:"py-2",attrs:{"cols":"12"}},[(_vm.PhotoLink!=null && _vm.PhotoLink!='' && _vm.PhotoLink!='null')?_c('img',{attrs:{"src":_vm.PhotoLink,"width":"100"}}):_vm._e(),(_vm.ProductCode=='C2023')?_c('span',{staticClass:"pa-5"},[_vm._v("ตุ๊กตา "+_vm._s(' '+_vm.StockName))]):_c('span',{staticClass:"pa-5"},[_vm._v(_vm._s(_vm.StockName))])])],1)],1),_c(VDivider),_c(VCardText,[_c(VRow,{staticClass:"py-1"},[_c(VCol,{staticClass:"py-1",attrs:{"cols":"12","md":"1"}},[_c('span',[_c('b',[_vm._v(" เลือกวันที่ : ")])])]),_c(VCol,{staticClass:"py-1",attrs:{"cols":"12","md":"3","lg":"2"}},[_c(VMenu,{ref:"menuStartDate",attrs:{"close-on-content-click":false,"return-value":_vm.startDate,"transition":"scale-transition","offset-y":"","min-width":"290px","max-width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"outlined":"","label":"เริ่มต้น","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.allowedDates},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c(VCol,{staticClass:"py-1",attrs:{"cols":"12","md":"3","lg":"2"}},[_c(VMenu,{ref:"menuEndDate",attrs:{"close-on-content-click":false,"return-value":_vm.endDate,"transition":"scale-transition","offset-y":"","min-width":"290px","max-width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"outlined":"","label":"สิ้นสุด","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.allowedDates},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c(VCol,{staticClass:"py-1",attrs:{"cols":"6","md":"3"}},[_c(VBtn,{staticClass:"fn-14",attrs:{"elevation":1,"color":"primary"},on:{"click":function($event){return _vm.StartPage()}}},[_c(VIcon,[_vm._v("mdi-magnify")]),_vm._v("ค้นหา")],1)],1),_c(VCol,{staticClass:"py-1",attrs:{"cols":"6","md":"4","align":"right"}},[_c(VBtn,{staticClass:"fn-14",attrs:{"elevation":1,"color":"success","outlined":""},on:{"click":function($event){return _vm.export_to_excell()}}},[_vm._v("export to excel")])],1)],1),_c(VRow,{staticClass:"hidden-sm-and-down"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{key:_vm.historyKey,staticClass:"packhai-border-table packhai-table",attrs:{"headers":_vm.headerHistory,"items":_vm.dataHistory,"hide-default-footer":"","items-per-page":_vm.itemsPerPageHistory},scopedSlots:_vm._u([{key:"item.Description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[(item.RemoveQuantity>0)?_c('span',{staticClass:"pl-10"},[_vm._v(_vm._s(item.Description))]):_c('span',[_vm._v(_vm._s(item.Description))])])]}},{key:"item.AddQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[(item.AddQuantity>0)?_c('span',{staticClass:"color-green"},[_c('b',[_vm._v(_vm._s(_vm.formatMoney(item.AddQuantity)))])]):_vm._e()])]}},{key:"item.RemoveQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[(item.RemoveQuantity>0)?_c('span',{staticClass:"color-red"},[_c('b',[_vm._v(_vm._s(_vm.formatMoney(item.RemoveQuantity)))])]):_vm._e()])]}},{key:"item.TotalQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.formatMoney(item.TotalQuantity)))])])])]}},{key:"item.Detail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Description)+" ")]}}])})],1)],1),_c(VRow,{staticClass:" hidden-md-and-up"},_vm._l((_vm.dataHistory),function(item,i){return _c(VCol,{key:i,attrs:{"cols":"12"}},[_c('div',{staticClass:"card-table d-flex flex-no-wrap"},[_c('div',{staticClass:"lineheight"},[(item.AddQuantity!='' && item.AddQuantity!=null)?_c('span',[_c('b',{staticStyle:{"color":"green"}},[_vm._v("สินค้าเข้า: ")]),_vm._v(_vm._s(item.AddQuantity)),_c('br')]):_c('span',[_c('b',{staticStyle:{"color":"red"}},[_vm._v("สินค้าออก: ")]),_vm._v(_vm._s(item.RemoveQuantity)),_c('br')]),_c('b',[_vm._v("วันที่: ")]),_vm._v(_vm._s(item.CreatedDatetime)),_c('br'),_c('b',[_vm._v("ID: ")]),_vm._v(_vm._s(item.ID)),_c('br'),_c('b',[_vm._v("รายละเอียด: ")]),_vm._v(_vm._s(item.Description)),_c('br')]),_c(VSpacer),_c('div',[_vm._v(" คงเหลือ: "+_vm._s(item.TotalQuantity)),_c('br')])],1)])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }